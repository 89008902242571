import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import RefundContent from '../components/Refund/RefundContent'
import api from '../api/credentials'

const Refund = ({ location }) => {
    const [order, setOrder] = useState()

    const params = new URLSearchParams(location.search)
    const id = params.get('id')

    useEffect(() => {
        (async () => {
            try {
                setOrder(null)
                api(`${process.env.BACKEND_URL}/sanctum/csrf-cookie`).then(() => {
                    api(`${process.env.API_URL}/order?id=${id}&refund=1`, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(data => {
                        setOrder(data.data)
                    }).catch(error => {
                        switch (error.response.status) {
                            case 401: // Not logged in
                            case 419: // Session expired
    
                                localStorage.removeItem('user')
                                window.dispatchEvent(new Event("removeUser"));
                                navigate('/signin')
                                break;
                           
                            default:
                                setOrder(null)
                                navigate('/404')

                        }
                    })
                })
            } catch (error) {
                setOrder(null)
                navigate('/404')

            }
        })()
    }, [id])

    if (!order) return null

    return (
        <Layout>
            <PageBanner />
            <RefundContent
                order={order}
            />
        </Layout>
    )
}

export default Refund