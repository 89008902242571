import React from 'react'

const RefundContent = ({ order }) => {
    return (
        <section className='ptb-50'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h4 className="card-title">Refund</h4>
                        <p className="card-text">
                            Se está procesando la devolución del pedido con número <strong>{order.id}</strong>.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RefundContent